$(document).ready(function(){
	$('.slider-gallery').slick({
		infinite: true,
		variableWidth: true,
		centerMode: true
	});
	$('.hero-background-slider').slick({
		infinite: true,
		arrows: false,
		autoplay: true,
		speed: 500,
		autoplaySpeed: 5000,
		fade: true
	});

	$('#menu-supernav .search a').on('click', function(event) {
		event.preventDefault();
		$('#menu-supernav .search').hide();
		$('.supernav-container .search-form').css('display', 'flex');
		$('.supernav-container .search-form input.search-field').focus();
	})
});

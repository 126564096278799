var lockTarget = document.querySelector("#mega-menu-container");

$(function(){
	$('#menu-toggle').on('click', function() {
		$('#mega-menu-container').fadeIn();
		bodyScrollLock.disableBodyScroll(lockTarget);
	});
	$('#menu-close').on('click', function() {
		$('#mega-menu-container').fadeOut();
		bodyScrollLock.clearAllBodyScrollLocks();
	});

	$('.sub-menu-toggle').on('click', function() {
		$(this).toggleClass('open');
		if( $(this).siblings('ul').is(':visible') ){
			fadeSubMenu( $(this).siblings('ul'), false );
		} else {
			fadeSubMenu( $(this).siblings('ul'), true );
		}
	});

	$('.top-level > .menu-item-has-children').hover( function() {
		$('.top-level > .menu-item-has-children').not( $(this) ).removeClass('open');
		if( $(window).outerWidth() > 991 ) {
			fadeSubMenu( $(this).children('ul.sub-menu'), true );
		}
	}, function() {
		if( $(window).outerWidth() > 991 ) {
			fadeSubMenu( $(this).children('ul.sub-menu'), false );
		}
	});

	$(window).on('resize', function() {
		// $('#mega-menu-container').fadeOut();
		// $('html').removeClass('menu-lock');
	});

});

$('.top-level > .menu-item-has-children a').on('keyup', function(event){
	var keycode = (event.keyCode ? event.keyCode : event.which);
	if(keycode == '13'){
		event.preventDefault();
		fadeSubMenu($('ul.sub-menu'), false);
		fadeSubMenu($(this).siblings('ul'), true);
	}
});

$('.menu-item-has-children > a').on('click', function (event) {
	if($(window).outerWidth() > 991 ){
		$('.menu-item-has-children').removeClass('open');
		$(this).parent('li').toggleClass('open');
	} else {
		if ( !$(this).data('submenutoggle') ) {
			event.preventDefault();
			$(this).data('submenutoggle', true);
		} else {
			$(this).data('submenutoggle', false);
		}

		if( $(this).siblings('ul').is(':visible')){
			fadeSubMenu( $(this).siblings('ul'), false);
		}else {
			fadeSubMenu( $(this).siblings('ul'), true );
		}
		$(this).siblings('.sub-menu-toggle').toggleClass('open');
	}
});

function fadeSubMenu(menu, fadeIn) {
	if(fadeIn) {
		menu.stop().fadeIn({
			duration: '150',
			start: function() {
				$(this).css('display','flex');
			}
		});
	} else {
		menu.stop().fadeOut({
			duration: '150',
			done: function() {
			}
		});
	}
}
